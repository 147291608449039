import React from "react"

import sticker from "./assets/page-discounts-sticker.png"
import DiscountTimeIcon from "../../../../assets/_V2/svg/DiscountTimeIcon"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getHeadingAndTextarea = (locale :Locales) => {
	if (locale === "kz-KZ") {
		return {
			subheading: "арнайы ұсыныстар",
			heading: (
				<>
					<span className={styles.contentDesktop}>Үнемдесеңіз - демек, ақша таптыңыз</span>
					<span className={styles.contentTablet}>Үнемдесеңіз - демек,<br />ақша таптыңыз</span>
					<span className={styles.contentMobile}>Үнемдесеңіз - демек, ақша таптыңыз</span>
				</>
			),
			headingType: "primary",
			textarea: (
				<>
					<img
						className={styles.textarea__image}
						src={sticker}
						alt="Актуальные предожения месяца"
						loading="lazy"
					/>
					<span className={styles.textarea}>
				<DiscountTimeIcon />
				Айдың өзекті ұсыныстары
			</span>
				</>
			),
		}
	}

	return {
		subheading: "специальные предложения",
		heading: (
			<>
				<span className={styles.contentDesktop}>Сэкономил — считай, заработал</span>
				<span className={styles.contentTablet}>Сэкономил — считай,<br/>заработал</span>
				<span className={styles.contentMobile}>Сэкономил — считай, заработал</span>
			</>
		),
		headingType: "primary",
		textarea: (
			<>
				<img
					className={styles.textarea__image}
					src={sticker}
					alt="Актуальные предожения месяца"
					loading="lazy"
				/>
				<span className={styles.textarea}>
				<DiscountTimeIcon />
				Актуальные предложения месяца
			</span>
			</>
		),
	}
}

export const headingAndTextarea = {
	subheading: "специальные предложения",
	heading: (
		<>
			<span className={styles.contentDesktop}>Сэкономил — считай, заработал</span>
			<span className={styles.contentTablet}>Сэкономил — считай,<br/>заработал</span>
			<span className={styles.contentMobile}>Сэкономил — считай, заработал</span>
		</>
	),
	headingType: "primary",
	textarea: (
		<>
			<img
				className={styles.textarea__image}
				src={sticker}
				alt="Актуальные предожения месяца"
				loading="lazy"
			/>
			<span className={styles.textarea}>
				<DiscountTimeIcon />
				Актуальные предложения месяца
			</span>
		</>
	),
}
