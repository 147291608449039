import React, {useState} from "react"
import classNames from "classnames"

import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "../../../_V2/common/links"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"
import Logo from "../../../../components/_V2/Header/assets/Logo";
import AdaptiveLink from "../../../../components/_V2/AdaptiveLink";
import ConsultationCtaFormA from "../../../../components/_V2/ConsultationCtaFormA";
import {Link} from "gatsby";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY'){
		return [
			{
				id: 0,
				heading: "Cкидка на технику",
				textarea: (
					<>
				<span className={styles.textarea__desktop}>
					Оформи годовую подписку и получи
					<br />
					специальные предложения на покупку
					<br />
					оборудования для своего заведения.
				</span>
						<span className={styles.textarea__tabletAndMobile}>
					Оформи годовую подписку и&nbsp;получи специальные предложения
					на&nbsp;покупку оборудования для своего заведения.
				</span>
					</>
				),
				btn: {
					text: "Подробнее",
					url: pagesLinks.shop.index.href,
					isButton: true,
				},
				mediaContent: (
					<>
						<StaticImage
							className={classNames(styles.image__desktop, styles.contentDesktop)}
							src="./assets/discounts-image1-d.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={styles.contentTablet}
							src="./assets/discounts-image1-t.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={styles.contentMobile}
							src="./assets/discounts-image1-m.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				popup: {
					heading: "Cкидка на технику",
					textarea: (
						<>
							Оформи годовую подписку и&nbsp;получи специальные предложения
							на&nbsp;покупку оборудования для своего заведения.
						</>
					),
					// btn: {
					// 	text: "В магазин",
					// 	url: pagesLinks.shop.index.href,
					// },
					mediaContent: (
						<StaticImage
							src="./assets/discounts-image1-modal.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					content: (
						<>
					<span className={styles.popup__text}>
						Акция действует на товары в нашем{" "}
						{/*<a href={pagesLinks.shop.index.href} className={styles.popup__link}>*/}
							интернет-магазине
						{/*</a>*/}
						.
					</span>

							<ul className={styles.popup__ul}>
								<li>
							<span>
								Скидка предоставляется на&nbsp;всё оборудование
								из&nbsp;ассортимента магазина
							</span>
								</li>
								<li>
							<span>
								Чтобы воспользоваться скидкой необходимо купить годовую подписку
							</span>
								</li>
								<li>
							<span>
								В&nbsp;карточке товара указана вторая цена со&nbsp;скидкой
							</span>
								</li>
							</ul>

							<h3 className={styles.popup__heading}>На что действует скидка</h3>
							<p className={styles.popup__text}>
								Приобрести со&nbsp;скидкой можно: Apple iPad, фискальные
								регистраторы, тикет-принтеры, стойки для iPad, роутеры, денежные
								ящики, весы, Quick Resto Set и&nbsp;прочее.
							</p>
						</>
					),
				},
			}
		]
	}
	if (locale === 'kz-KZ'){
		return [
			{
				id: 0,
				heading: "Техникаға жеңілдік",
				textarea: (
					<>
				<span className={styles.textarea__desktop}>
					Жылдық жазылымды ресімдеңіз және мекемеңіз үшін жабдықты сатып алуға арнайы ұсыныстар алыңыз.
				</span>
						<span className={styles.textarea__tabletAndMobile}>
					Жылдық жазылымды ресімдеңіз және мекемеңіз үшін жабдықты сатып алуға арнайы ұсыныстар алыңыз.
				</span>
					</>
				),
				btn: {
					text: "Толығырақ",
					url: pagesLinks.shop.index.href,
					isButton: true,
				},
				mediaContent: (
					<>
						<StaticImage
							className={classNames(styles.image__desktop, styles.contentDesktop)}
							src="./assets/discounts-image1-d.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={styles.contentTablet}
							src="./assets/discounts-image1-t.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={styles.contentMobile}
							src="./assets/discounts-image1-m.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				popup: {
					heading: "Техникаға жеңілдік",
					textarea: (
						<>
							Жылдық жазылымды ресімдеңіз және мекемеңіз үшін жабдықты сатып алуға арнайы ұсыныстар алыңыз.
						</>
					),
					// btn: {
					// 	text: "В магазин",
					// 	url: pagesLinks.shop.index.href,
					// },
					mediaContent: (
						<StaticImage
							src="./assets/discounts-image1-modal.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					content: (
						<>
					<span className={styles.popup__text}>
						Акция интернет-дүкеніміздегі тауарларға қолданылады.
					</span>

							<ul className={styles.popup__ul}>
								<li>
							<span>
								Жеңілдік дүкен ассортиментінен барлық жабдықтарға беріледі
							</span>
								</li>
								<li>
							<span>
								Жеңілдікті пайдалану үшін жылдық жазылымды сатып алу қажет
							</span>
								</li>
								<li>
							<span>
								Тауардың карточкасында екінші баға жеңілдікпен көрсетілген
							</span>
								</li>
							</ul>

							<h3 className={styles.popup__heading}>Жеңілдік неге қолданылады</h3>
							<p className={styles.popup__text}>
								Жеңілдікпен Apple iPad, фискалдық тіркегіштерді, тиект-принтерлерді, iPad арналған тіреулерді, роутерлерді, ақша жәшіктерін, таразыны, Quick Resto Set және басқаларды сатып алуға болады.
							</p>
						</>
					),
				},
			}
		]
	}
	if (locale === 'ru-KZ'){
		return [
			{
				id: 0,
				heading: "Cкидка на технику",
				textarea: (
					<>
				<span className={styles.textarea__desktop}>
					Оформи годовую подписку и получи
					<br />
					специальные предложения на покупку
					<br />
					оборудования для своего заведения.
				</span>
						<span className={styles.textarea__tabletAndMobile}>
					Оформи годовую подписку и&nbsp;получи специальные предложения
					на&nbsp;покупку оборудования для своего заведения.
				</span>
					</>
				),
				btn: {
					text: "Подробнее",
					url: pagesLinks.shop.index.href,
					isButton: true,
				},
				mediaContent: (
					<>
						<StaticImage
							className={classNames(styles.image__desktop, styles.contentDesktop)}
							src="./assets/discounts-image1-d.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={styles.contentTablet}
							src="./assets/discounts-image1-t.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							className={styles.contentMobile}
							src="./assets/discounts-image1-m.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				popup: {
					heading: "Cкидка на технику",
					textarea: (
						<>
							Оформи годовую подписку и&nbsp;получи специальные предложения
							на&nbsp;покупку оборудования для своего заведения.
						</>
					),
					// btn: {
					// 	text: "В магазин",
					// 	url: pagesLinks.shop.index.href,
					// },
					mediaContent: (
						<StaticImage
							src="./assets/discounts-image1-modal.png"
							alt="Cкидка на технику"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					content: (
						<>
					<span className={styles.popup__text}>
						Акция действует на товары в нашем{" "}
						{/*<a href={pagesLinks.shop.index.href} className={styles.popup__link}>*/}
							интернет-магазине
						{/*</a>*/}
						.
					</span>

							<ul className={styles.popup__ul}>
								<li>
							<span>
								Скидка предоставляется на&nbsp;всё оборудование
								из&nbsp;ассортимента магазина
							</span>
								</li>
								<li>
							<span>
								Чтобы воспользоваться скидкой необходимо купить годовую подписку
							</span>
								</li>
								<li>
							<span>
								В&nbsp;карточке товара указана вторая цена со&nbsp;скидкой
							</span>
								</li>
							</ul>

							<h3 className={styles.popup__heading}>На что действует скидка</h3>
							<p className={styles.popup__text}>
								Приобрести со&nbsp;скидкой можно: Apple iPad, фискальные
								регистраторы, тикет-принтеры, стойки для iPad, роутеры, денежные
								ящики, весы, Quick Resto Set и&nbsp;прочее.
							</p>
						</>
					),
				},
			}
		]
	}
	return [
		{
			id: 0,
			heading: "Cкидка на технику",
			textarea: (
				<>
				<span className={styles.textarea__desktop}>
					Оформи годовую подписку и получи
					<br />
					специальные предложения на покупку
					<br />
					оборудования для своего заведения.
				</span>
					<span className={styles.textarea__tabletAndMobile}>
					Оформи годовую подписку и&nbsp;получи специальные предложения
					на&nbsp;покупку оборудования для своего заведения.
				</span>
				</>
			),
			btn: {
				text: "Подробнее",
				url: pagesLinks.shop.index.href,
				isButton: true,
			},
			mediaContent: (
				<>
					<StaticImage
						className={classNames(styles.image__desktop, styles.contentDesktop)}
						src="./assets/discounts-image1-d.png"
						alt="Cкидка на технику"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.contentTablet}
						src="./assets/discounts-image1-t.png"
						alt="Cкидка на технику"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.contentMobile}
						src="./assets/discounts-image1-m.png"
						alt="Cкидка на технику"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
			popup: {
				heading: "Cкидка на технику",
				textarea: (
					<>
						Оформи годовую подписку и&nbsp;получи специальные предложения
						на&nbsp;покупку оборудования для своего заведения.
					</>
				),
				btn: {
					text: "В магазин",
					url: pagesLinks.shop.index.href,
				},
				mediaContent: (
					<StaticImage
						src="./assets/discounts-image1-modal.png"
						alt="Cкидка на технику"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				content: (
					<>
					<span className={styles.popup__text}>
						Акция действует на товары в нашем{" "}
						<a href={pagesLinks.shop.index.href} className={styles.popup__link}>
							интернет-магазине
						</a>
						.
					</span>

						<ul className={styles.popup__ul}>
							<li>
							<span>
								Скидка предоставляется на&nbsp;всё оборудование
								из&nbsp;ассортимента магазина
							</span>
							</li>
							<li>
							<span>
								Чтобы воспользоваться скидкой необходимо купить годовую подписку
							</span>
							</li>
							<li>
							<span>
								В&nbsp;карточке товара указана вторая цена со&nbsp;скидкой
							</span>
							</li>
						</ul>

						<h3 className={styles.popup__heading}>На что действует скидка</h3>
						<p className={styles.popup__text}>
							Приобрести со&nbsp;скидкой можно: Apple iPad, фискальные
							регистраторы, тикет-принтеры, стойки для iPad, роутеры, денежные
							ящики, весы, Quick Resto Set и&nbsp;прочее.
						</p>
					</>
				),
			},
		},
		// {
		// 	id: 3,
		// 	heading: "iPad в подарок",
		// 	textarea: (
		// 		<>
		// 		<span className={styles.textarea__desktop}>
		// 			Купи годовую подписку вместе с сайтом
		// 			<br />
		// 			или приложением для гостей и получи
		// 			<br />
		// 			iPad в подарок
		// 		</span>
		// 			<span className={styles.textarea__tabletAndMobile}>
		// 			Купи годовую подписку вместе с сайтом или приложением для гостей и получи iPad в подарок
		// 		</span>
		// 		</>
		// 	),
		// 	btn: {
		// 		text: "Подробнее",
		// 		url: pagesLinks.shop.index.href,
		// 		isButton: true,
		// 	},
		// 	mediaContent: (
		// 		<>
		// 			<StaticImage
		// 				className={classNames(styles.image__desktop, styles.contentDesktop)}
		// 				src="./assets/discounts-image3-d.png"
		// 				alt="iPad в подарок"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 			<StaticImage
		// 				className={styles.contentTablet}
		// 				src="./assets/discounts-image3-t.png"
		// 				alt="iPad в подарок"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 			<StaticImage
		// 				className={styles.contentMobile}
		// 				src="./assets/discounts-image3-m.png"
		// 				alt="iPad в подарок"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 		</>
		// 	),
		// 	popup: {
		// 		heading: "iPad в подарок",
		// 		textarea: (
		// 			<>
		// 				Дарим iPad за покупку годовой подписки Standard или Pro и одного дополнительного продукта:
		// 				сайта или мобильного приложения.
		// 				<p className={styles.smallDescription}>*Предоставляется б/у Apple iPad Air 2 (2014).</p>
		// 			</>
		// 		),
		// 		btn: {
		// 			text: "Заказать звонок",
		// 			isButton: true
		// 		},
		// 		mediaContent: (
		// 			<StaticImage
		// 				src="./assets/discounts-image3-modal.png"
		// 				alt="iPad в подарок"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 		),
		// 		content: (
		// 			<>
		// 				<h3 className={styles.popup__heading}>Условия акции</h3>
		// 				<p className={styles.popup__text}>
		// 					Предоставляется <a href={"shop/apple_ipad/apple_ipad_air_5_e_pokolenie_b_u/"} className={styles.popup__link}> Apple iPad Air 2 (2014)</a> (б/у).
		// 					Планшет протестирован и технически исправен. Может работать в качестве терминала кассира,
		// 					экрана повара или экрана покупателя.
		// 					< br/>
		// 					< br/>
		// 					Чтобы получить подарок, оплати подписку и дополнительный продукт одним счетом.
		// 					Акция действует для новых клиентов Quick Resto.
		// 				</p>
		// 			</>
		// 		),
		// 	},
		//
		// },
		// {
		// 	id: 1,
		// 	heading: <>Стань PRO за 8&nbsp;970&nbsp;₽!</>,
		// 	textarea: (
		// 		<>
		// 		<span className={styles.textarea__desktop}>
		// 			Для тех, кто уже чувствует потребность
		// 			<br />
		// 			в продвинутом учёте и аналитике, либо
		// 			<br />
		// 			расширяет свой бизнес.
		// 		</span>
		// 			<span className={styles.textarea__tabletAndMobile}>
		// 			Для тех, кто уже чувствует потребность в продвинутом учёте и
		// 			аналитике, либо расширяет свой бизнес.
		// 		</span>
		// 		</>
		// 	),
		// 	btn: {
		// 		text: "Подробнее",
		// 		url: pagesLinks.discounts.href,
		// 		isButton: true,
		// 	},
		// 	sticker: "популярная акция",
		// 	mediaContent: (
		// 		<>
		// 			<StaticImage
		// 				className={classNames(styles.image__desktop, styles.contentDesktop)}
		// 				src="./assets/discounts-image2-d.png"
		// 				alt="Стань PRO за 8 970 ₽!"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 			<StaticImage
		// 				className={styles.contentTablet}
		// 				src="./assets/discounts-image2-t.png"
		// 				alt="Стань PRO за 8 970 ₽!"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 			<StaticImage
		// 				className={styles.contentMobile}
		// 				src="./assets/discounts-image2-m.png"
		// 				alt="Стань PRO за 8 970 ₽!"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 		</>
		// 	),
		// 	popup: {
		// 		heading: (
		// 			<>
		// 				Стань Pro за 8 970 ₽!{" "}
		// 				<span className={styles.heading__price}>14 970 ₽</span>
		// 			</>
		// 		),
		// 		textarea: (
		// 			<>
		// 				Предложение для тех, кто расширяет бизнес или чувствует потребность
		// 				в&nbsp;продвинутом учёте и&nbsp;аналитике.
		// 			</>
		// 		),
		// 		btn: {
		// 			text: "Подключить",
		// 			isButton: true,
		// 		},
		// 		mediaContent: (
		// 			<StaticImage
		// 				src="./assets/discounts-image2-modal.png"
		// 				alt="Cкидка на технику"
		// 				objectFit="contain"
		// 				placeholder="blurred"
		// 				quality={90}
		// 			/>
		// 		),
		// 		content: (
		// 			<>
		// 				<h3 className={styles.popup__heading}>Ты получаешь</h3>
		//
		// 				<ul className={styles.popup__ul}>
		// 					<li>
		// 					<span>
		// 						Расширенный набор инструментов учёта&nbsp;&mdash; акты разбора
		// 						и&nbsp;акты переработки
		// 					</span>
		// 					</li>
		// 					<li>
		// 						<span>Безлимитное количество терминалов</span>
		// 					</li>
		// 					<li>
		// 					<span>
		// 						Настраиваемые типы оплат: не&nbsp;только наличный, безналичный
		// 						расчёт и&nbsp;бонусы, но&nbsp;и&nbsp;пользовательский
		// 						тип&nbsp;&mdash; для питания сотрудников или обслуживания
		// 						VIP-гостей за&nbsp;счёт заведения
		// 					</span>
		// 					</li>
		// 					<li>
		// 					<span>
		// 						Персональные отчёты под любую задачу&nbsp;&mdash; создавайте
		// 						отчёты с любыми вводными, чтобы получить нужные данные для
		// 						аналитики
		// 					</span>
		// 					</li>
		// 				</ul>
		//
		// 				<p className={styles.popup__text}>
		// 					Воспользуйся максимальными возможностями автоматизации от&nbsp;Quick
		// 					Resto&nbsp;&mdash; работай на&nbsp;тарифе Pro по&nbsp;выгодной цене.
		// 					Предложение действительно один раз для любого активного облака,
		// 					работающего на&nbsp;тарифе <span className={styles.popup__link}>Start</span> или <span className={styles.popup__link}>Standard</span>.
		// 				</p>
		// 			</>
		// 		),
		// 	},
		// },
	]
}

export const cardListHalfWidth = [
	{
		id: 0,
		heading: "Cкидка на технику",
		textarea: (
			<>
				<span className={styles.textarea__desktop}>
					Оформи годовую подписку и получи
					<br />
					специальные предложения на покупку
					<br />
					оборудования для своего заведения.
				</span>
				<span className={styles.textarea__tabletAndMobile}>
					Оформи годовую подписку и&nbsp;получи специальные предложения
					на&nbsp;покупку оборудования для своего заведения.
				</span>
			</>
		),
		btn: {
			text: "Подробнее",
			url: pagesLinks.shop.index.href,
			isButton: true,
		},
		mediaContent: (
			<>
				<StaticImage
					className={classNames(styles.image__desktop, styles.contentDesktop)}
					src="./assets/discounts-image1-d.png"
					alt="Cкидка на технику"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					className={styles.contentTablet}
					src="./assets/discounts-image1-t.png"
					alt="Cкидка на технику"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					className={styles.contentMobile}
					src="./assets/discounts-image1-m.png"
					alt="Cкидка на технику"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</>
		),
		popup: {
			heading: "Cкидка на технику",
			textarea: (
				<>
					Оформи годовую подписку и&nbsp;получи специальные предложения
					на&nbsp;покупку оборудования для своего заведения.
				</>
			),
			btn: {
				text: "В магазин",
				url: pagesLinks.shop.index.href,
			},
			mediaContent: (
				<StaticImage
					src="./assets/discounts-image1-modal.png"
					alt="Cкидка на технику"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			content: (
				<>
					<span className={styles.popup__text}>
						Акция действует на товары в нашем{" "}
						<a href={pagesLinks.shop.index.href} className={styles.popup__link}>
							интернет-магазине
						</a>
						.
					</span>

					<ul className={styles.popup__ul}>
						<li>
							<span>
								Скидка предоставляется на&nbsp;всё оборудование
								из&nbsp;ассортимента магазина
							</span>
						</li>
						<li>
							<span>
								Чтобы воспользоваться скидкой необходимо купить годовую подписку
							</span>
						</li>
						<li>
							<span>
								В&nbsp;карточке товара указана вторая цена со&nbsp;скидкой
							</span>
						</li>
					</ul>

					<h3 className={styles.popup__heading}>На что действует скидка</h3>
					<p className={styles.popup__text}>
						Приобрести со&nbsp;скидкой можно: Apple iPad, фискальные
						регистраторы, тикет-принтеры, стойки для iPad, роутеры, денежные
						ящики, весы, Quick Resto Set и&nbsp;прочее.
					</p>
				</>
			),
		},
	},
	// {
	// 	id: 1,
	// 	heading: <>Стань PRO за 8&nbsp;970&nbsp;₽!</>,
	// 	textarea: (
	// 		<>
	// 			<span className={styles.textarea__desktop}>
	// 				Для тех, кто уже чувствует потребность
	// 				<br />
	// 				в продвинутом учёте и аналитике, либо
	// 				<br />
	// 				расширяет свой бизнес.
	// 			</span>
	// 			<span className={styles.textarea__tabletAndMobile}>
	// 				Для тех, кто уже чувствует потребность в продвинутом учёте и
	// 				аналитике, либо расширяет свой бизнес.
	// 			</span>
	// 		</>
	// 	),
	// 	btn: {
	// 		text: "Подробнее",
	// 		url: pagesLinks.discounts.href,
	// 		isButton: true,
	// 	},
	// 	sticker: "популярная акция",
	// 	mediaContent: (
	// 		<>
	// 			<StaticImage
	// 				className={classNames(styles.image__desktop, styles.contentDesktop)}
	// 				src="./assets/discounts-image2-d.png"
	// 				alt="Стань PRO за 8 970 ₽!"
	// 				objectFit="contain"
	// 				placeholder="blurred"
	// 				quality={90}
	// 			/>
	// 			<StaticImage
	// 				className={styles.contentTablet}
	// 				src="./assets/discounts-image2-t.png"
	// 				alt="Стань PRO за 8 970 ₽!"
	// 				objectFit="contain"
	// 				placeholder="blurred"
	// 				quality={90}
	// 			/>
	// 			<StaticImage
	// 				className={styles.contentMobile}
	// 				src="./assets/discounts-image2-m.png"
	// 				alt="Стань PRO за 8 970 ₽!"
	// 				objectFit="contain"
	// 				placeholder="blurred"
	// 				quality={90}
	// 			/>
	// 		</>
	// 	),
	// 	popup: {
	// 		heading: (
	// 			<>
	// 				Стань Pro за 8 970 ₽!{" "}
	// 				<span className={styles.heading__price}>14 970 ₽</span>
	// 			</>
	// 		),
	// 		textarea: (
	// 			<>
	// 				Предложение для тех, кто расширяет бизнес или чувствует потребность
	// 				в&nbsp;продвинутом учёте и&nbsp;аналитике.
	// 			</>
	// 		),
	// 		btn: {
	// 			text: "Подключить",
	// 			isButton: true,
	// 		},
	// 		mediaContent: (
	// 			<StaticImage
	// 				src="./assets/discounts-image2-modal.png"
	// 				alt="Cкидка на технику"
	// 				objectFit="contain"
	// 				placeholder="blurred"
	// 				quality={90}
	// 			/>
	// 		),
	// 		content: (
	// 			<>
	// 				<h3 className={styles.popup__heading}>Ты получаешь</h3>
	//
	// 				<ul className={styles.popup__ul}>
	// 					<li>
	// 						<span>
	// 							Расширенный набор инструментов учёта&nbsp;&mdash; акты разбора
	// 							и&nbsp;акты переработки
	// 						</span>
	// 					</li>
	// 					<li>
	// 						<span>Безлимитное количество терминалов</span>
	// 					</li>
	// 					<li>
	// 						<span>
	// 							Настраиваемые типы оплат: не&nbsp;только наличный, безналичный
	// 							расчёт и&nbsp;бонусы, но&nbsp;и&nbsp;пользовательский
	// 							тип&nbsp;&mdash; для питания сотрудников или обслуживания
	// 							VIP-гостей за&nbsp;счёт заведения
	// 						</span>
	// 					</li>
	// 					<li>
	// 						<span>
	// 							Персональные отчёты под любую задачу&nbsp;&mdash; создавайте
	// 							отчёты с любыми вводными, чтобы получить нужные данные для
	// 							аналитики
	// 						</span>
	// 					</li>
	// 				</ul>
	//
	// 				<p className={styles.popup__text}>
	// 					Воспользуйся максимальными возможностями автоматизации от&nbsp;Quick
	// 					Resto&nbsp;&mdash; работай на&nbsp;тарифе Pro по&nbsp;выгодной цене.
	// 					Предложение действительно один раз для любого активного облака,
	// 					работающего на&nbsp;тарифе <span className={styles.popup__link}>Start</span> или <span className={styles.popup__link}>Standard</span>.
	// 				</p>
	// 			</>
	// 		),
	// 	},
	// },
]
